<template>
  <section class="py-5 px-2 text-start col-md-8 col-10 mx-auto">
      <h2><mark>Get Premium</mark></h2>
    <h3>Do you find Chachi useful? It can get even better!</h3>
    <p>We will soon launch a premium subscription to Chachi that adds more features. See the details below!</p>
    <p>Don't worry. The content will always remain free. :)</p>

    <form id='pro-signup-form' class='py-4'>
        <h4>Enter your email below and get 50% off!</h4>
        <p>When the premium features are ready, we will invite you through email. And you will automatically get a 50% discount.</p>
        <div class='row row-cols-1 text-center py-4' id='input-row'>
            <div class='col col-sm-6 mx-auto'>
                <input type="email" class="form-control" id="proSignupInput" v-model="proSignupEmail" placeholder="name@example.com">
            </div>
            <div class='col my-2'>
                <button type="button" class="btn btn-primary" id='pro-signup-btn' @click="proSignupFill()">{{$t('signup-button-yes')}}</button>
                <button type="button" class="btn btn-primary d-none" id='pro-signup-btn-spinner'>
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </button>
            </div>
        </div>
        <div class='text-center py-4 d-none' id='response-row'>
            <h5 id='signup-success' class='d-none'>Amazing! Thank you for your support.</h5>
            <h5 id='signup-fail' class='d-none'>Oops. That didn't work. Would you mind trying again?</h5>
        </div>
        <p>Have suggestions? Questions? Email me at gurjot@chachi.app!</p>
    </form>

    <h3 class='py-4'>Compare features</h3>
    <div class='col-lg-8 col-12 mx-auto' id='premium-features-table'>
        <table class="table text-center">
            <thead>
            <tr>
                <th style="width: 40%;">Features</th>
                <th style="width: 30%;">Free</th>
                <th style="width: 30%;">Premium</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="text-start">Browse cards</th>
                <td class='text-muted'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Select categories</th>
                <td class='text-muted'>One at a time</td>
                <td>Multiple combinations</td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Add comments and replies</th>
                <td class='text-muted'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Bookmark cards</th>
                <td class='text-muted'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Add tags to bookmarks</th>
                <td></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Add notes to cards</th>
                <td></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Create worksheets from cards</th>
                <td></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            <tr>
                <th scope="row" class="text-start">Exclusive suggestions from noted educationists</th>
                <td></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/></svg></td>
            </tr>
            </tbody>
        </table>
        <div class='text-center'>
            <button class='btn btn-outline-success' @click="scrollToInput()">I want the premium experience!</button>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'ProSignUp',
  metaInfo: {
    title: 'Get Premium',
    titleTemplate: '%s | Chachi',
  },
  props: {
  },
  data() {
    return {
      proSignupEmail: ''
    }
  },
  computed: mapState({
    uid: state => state.currentUser.uid
  }),
  methods: {
    proSignupFill() {
        if (typeof(this.uid) === "undefined") {
            var api_link = "https://api.chachi.app/v2/users/premium-pre-signup/" + this.proSignupEmail + "/null" 
        } else {
            api_link = "https://api.chachi.app/v2/users/premium-pre-signup/" + this.proSignupEmail + "/" + this.uid
        }
        axios.post(api_link)
            .then( response => {
                if (response.data) {
                    document.getElementById('signup-fail').classList.add('d-none')
                    document.getElementById('input-row').classList.add('d-none')
                    document.getElementById('response-row').classList.remove('d-none')
                    document.getElementById('signup-success').classList.remove('d-none')
                } else {
                    document.getElementById('response-row').classList.remove('d-none')
                    document.getElementById('signup-fail').classList.remove('d-none')
                    document.getElementById('signup-success').classList.add('d-none')
                }
            })
            .catch( err => {
                console.log(err)
                document.getElementById('response-row').classList.remove('d-none')
                document.getElementById('signup-fail').classList.remove('d-none')
                document.getElementById('signup-success').classList.add('d-none')
            })
        document.getElementById('pro-signup-btn').classList.add('d-none')
        document.getElementById('pro-signup-btn-spinner').classList.remove('d-none')
    },
    scrollToInput() {
        document.getElementById("proSignupInput").classList.add('glow');
        document.getElementById("pro-signup-form").scrollIntoView();
    }
  }
}
</script>

<style scoped>
@keyframes glowing {
  0% { box-shadow: 0 0 -5px #218739; }
  40% { box-shadow: 0 0 10px #218739; }
  60% { box-shadow: 0 0 10px #218739; }
  100% { box-shadow: 0 0 -5px #218739; }
}
.glow {
  animation: glowing 3000ms infinite;
}
#pro-signup-btn-spinner {
    width: 148.483px;
}
.spinner-border {
    width: 1rem;
    height: 1rem;
}
</style>